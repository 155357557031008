@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Inter';
	src:
		local('Inter'),
		url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}

@layer base {
	:root {
		--color-text-primary: 57 59 65;
		--color-text-secondary: 77 80 88;
		--color-text-tertiary: 148 150 155;
		--color-text-quaternary: 184 185 188;
		--color-text-quinary: 243 243 243;
		--color-text-accent: 51 204 153;
		--color-text-hover: 51 188 153;
		--color-text-information: 44 160 248;
		--color-text-notice: 255 127 102;
		--color-text-warning: 255 127 102;
		--color-text-tag-blue: 7 78 197;
		--color-text-tag-brown: 87 62 23;
		--color-text-tag-bordeaux: 74 18 18;
		--color-text-graph-orange-light: 248 231 214;
		--color-text-graph-orange-dark: 220 156 52;
		--color-text-graph-green-light: 173 235 214;
		--color-text-graph-green-dark: 51 164 153;
		--color-theme-background-primary: 254 254 254;
		--color-theme-background-secondary: 239 239 239;
		--color-theme-background-tertiary: 239 239 239;
		--color-theme-background-quaternary: 254 254 254;
		--color-theme-background-popup: 57 59 65;
		--color-button-primary-default: 51 204 153;
		--color-button-primary-hover: 51 180 153;
		--color-button-primary-active: 51 172 153;
		--color-button-secondary-default: 243 243 243;
		--color-button-delete-default: 255 73 73;
		--color-button-delete-hover: 255 10 10;
		--color-button-delete-active: 224 0 0;
		--color-button-disabled: 219 220 222;
		--color-tag-bordeaux-light: 255 233 233;
		--color-tag-brown-light: 255 244 226;
		--color-tag-blue-light: 213 236 254;
		--color-tag-red-dark: 255 73 73;
		--color-tag-orange-dark: 220 156 52;
		--color-tag-blue-dark: 44 160 248;
		--color-tag-default: 239 239 239;
		--color-tag-selected: 173 235 214;
		--color-toggle-white: 254 254 254;
		--color-toggle-ligh: 239 239 239;
		--color-toggle-dark: 77 80 88;
		--color-toggle-darker: 57 59 65;
		--color-toggle-active: 51 204 153;
		--color-graph-orange-light: 248 231 214;
		--color-graph-orange-dark: 220 156 52;
		--color-graph-green-light: 173 235 214;
		--color-graph-green-dark: 51 164 153;
		--color-regular-icons-primary: 254 254 254;
		--color-regular-icons-secondary: 184 185 188;
		--color-regular-icons-tertiary: 77 80 88;
		--color-regular-icons-active: 51 204 153;
		--color-regular-icons-warning: 255 73 73;
		--color-menu-icons-default: 184 185 188;
		--color-menu-icons-hover: 77 80 88;
		--color-menu-icons-active: 51 204 153;
		--color-border-default: 219 220 222;
		--color-border-hover: 133 224 194;
		--color-border-active: 51 204 153;
		--color-divider-primary: 239 239 239;
		--color-divider-secondary: 219 220 222;
	}

	:root[class~='dark'] {
		--color-text-primary: 254 254 254;
		--color-text-secondary: 243 243 243;
		--color-text-tertiary: 148 150 155;
		--color-text-quaternary: 148 150 155;
		--color-text-quinary: 243 243 243;
		--color-text-accent: 51 204 153;
		--color-text-hover: 51 188 153;
		--color-text-information: 44 160 248;
		--color-text-notice: 255 127 102;
		--color-text-warning: 255 127 102;
		--color-text-tag-blue: 254 254 254;
		--color-text-tag-brown: 254 254 254;
		--color-text-tag-bordeaux: 254 254 254;
		--color-text-graph-orange-light: 248 231 214;
		--color-text-graph-orange-dark: 220 156 52;
		--color-text-graph-green-light: 173 235 214;
		--color-text-graph-green-dark: 51 164 153;
		--color-theme-background-primary: 57 59 65;
		--color-theme-background-secondary: 77 80 88;
		--color-theme-background-tertiary: 33 35 38;
		--color-theme-background-quaternary: 77 80 88;
		--color-theme-background-popup: 14 15 16;
		--color-button-primary-default: 51 164 153;
		--color-button-primary-hover: 51 180 153;
		--color-button-primary-active: 51 172 153;
		--color-button-secondary-default: 113 115 121;
		--color-button-delete-default: 255 73 73;
		--color-button-delete-hover: 255 10 10;
		--color-button-delete-active: 224 0 0;
		--color-button-disabled: 219 220 222;
		--color-tag-bordeaux-light: 255 73 73;
		--color-tag-brown-light: 220 156 52;
		--color-tag-blue-light: 44 144 248;
		--color-tag-red-dark: 255 73 73;
		--color-tag-orange-dark: 220 156 52;
		--color-tag-blue-dark: 44 160 248;
		--color-tag-default: 77 80 88;
		--color-tag-selected: 173 235 214;
		--color-toggle-white: 254 254 254;
		--color-toggle-ligh: 239 239 239;
		--color-toggle-dark: 77 80 88;
		--color-toggle-darker: 57 59 65;
		--color-toggle-active: 51 164 153;
		--color-graph-orange-light: 248 231 214;
		--color-graph-orange-dark: 220 156 52;
		--color-graph-green-light: 173 235 214;
		--color-graph-green-dark: 51 164 153;
		--color-regular-icons-primary: 254 254 254;
		--color-regular-icons-secondary: 184 185 188;
		--color-regular-icons-tertiary: 77 80 88;
		--color-regular-icons-active: 51 204 153;
		--color-regular-icons-warning: 255 73 73;
		--color-menu-icons-default: 184 185 188;
		--color-menu-icons-hover: 219 220 222;
		--color-menu-icons-active: 51 204 153;
		--color-border-default: 219 220 222;
		--color-border-hover: 133 224 194;
		--color-border-active: 51 164 153;
		--color-divider-primary: 77 80 88;
		--color-divider-secondary: 77 80 88;
	}
}
